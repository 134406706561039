import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { Router, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { SoonComponent } from './soon/soon.component';
import { AnnimatedComponent } from './annimated/annimated.component';


const routes:Routes = [
                        {path:"home",component:HomeComponent},
                        {path:"soon",component:SoonComponent},
                        {path:"",redirectTo:'home',pathMatch:'full'},
                        ]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SoonComponent,
    AnnimatedComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    CommonModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
