import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(private loc: Location) { }

  firstScroll = true;
  firstExit = false;

  ngAfterViewInit(): void {
    this.observe('landingVideo');

  }

  ngOnInit(): void {
  }

  back() {
    this.loc.back();
  }



  observe(what) {
    let options = {

      root: null,
      rootMargin: '0px',
      threshold: 1.0

    }

    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.target.id == what) {
          if (entry.isIntersecting && this.firstScroll) {
            entry.target.play();
            this.firstScroll = false;

          } else {
            if (!this.firstExit) {
              entry.target.pause();
              if (!this.firstScroll) {
                this.firstExit = true;
              }
            }
          }
        }
      });
    }

    let observer = new IntersectionObserver(callback, options);
    observer.observe(document.querySelector('#' + what));
  }


  clickedVideo(ID) {
    var idList = ['landingVideo', 'eeveverwaltung', 'eevemail', 'eevevoip'];
    for (let id of idList) {
      if (id != ID) {
        var vid = <HTMLVideoElement>document.getElementById(id)
        vid.pause();
      }
    }
  }
}






