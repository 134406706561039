<!-- eeve-logo -->
<div class="jumbotron jumbotron-fluid">
  <div id="MainLogo">
    <a href="https://my.eeve.online/"
      ><img src="https://czichos.net/assets/images/eeve_logo_red.svg"
    /></a>
  </div>
  <!--h1  id="ueberschrift">Die neue Art der digitalen Kommunikation </h1-->
</div>

<!-- carousel -->
<div class="container container-white" id="slidercontainer">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
    data-interval="7500"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <h1 class="responsive-display-4">
          Einfach, vernetzt und ortsunabhängig Arbeiten
        </h1>
        <p class="responsive-lead">
          eeve.online ist eine, auf Open-Source basierende, in Berlin gehostete
          Kommunikationsplattform.
        </p>
        <p class="responsive-lead">
          Intuitive Benutzbarkeit. Mit nur einem Passwort einmal anmelden, und
          Sie können direkt alle Dienste nutzen. Keine Installation oder
          komplizierte Einrichtung.
        </p>
      </div>
      <div class="carousel-item">
        <h1 class="responsive-display-4">
          Sicher, immer verfügbar und ohne aufwändige Wartung
        </h1>
        <p class="responsive-lead">
          Dank der Anwendung im Browser benötigen Sie keinen lästige
          Softwareupdates oder neue Lizenzen auf den Endgeräten.
        </p>
        <p class="responsive-lead">Sie müssen nichts tun, außer Ihren Job!</p>
      </div>
      <div class="carousel-item">
        <h1 class="responsive-display-4">Alle Dienste aus einer Hand</h1>
        <p class="responsive-lead">
          Benutzen Sie E-Mail, Video, Chat und VoIP auf einer integrierten
          Plattform.
        </p>
        <p class="responsive-lead">Ein Ansprechpartner für alle Aspekte.</p>
      </div>
      <div class="carousel-item">
        <h1 class="responsive-display-4">Ihre Daten sicher an einem Ort</h1>
        <p class="responsive-lead">
          Durch die zentrale Verwaltungsoberfläche, die via Single-Sign-On
          Zugang zu den anderen Anwendungen gewährt, sind Ihre persönlichen
          Daten nur ein einem Ort!
        </p>
        <p class="responsive-lead">
          In unserem Hochsicherheits Datencenter in Berlin!
        </p>
      </div>
      <!-- <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol> -->
    </div>
  </div>
</div>

<!-- landing-video -->
<div class="jumbotron jumbotron-fluid" id="landing-video">
  <h1 class="responsive-display-4">Was ist eeve.online?</h1>
  <video
    width="auto"
    controls
    mute
    id="landingVideo"
    (play)="clickedVideo('landingVideo')"
  >
    <source
      src="https://czichos.net/assets/images/eeve-videos/eeve-landing.mp4"
      type="video/mp4"
    />
  </video>
</div>

<!-- varwaltung-video -->
<div class="container container-white">
  <h1 class="responsive-display-4">Die Verwaltungsoberfläche</h1>
  <div class="row">
    <video
      width="auto"
      height="90%"
      controls
      mute
      id="eeveverwaltung"
      class="col-md-6"
      (play)="clickedVideo('eeveverwaltung')"
    >
      <source
        src="https://czichos.net/assets/images/eeve-videos/eeve-verwaltungsoberflaeche.mp4"
        type="video/mp4"
      />
    </video>
    <div class="col-md-6" id="Verwaltungsoberflaeche">
      <p class="responsive-lead">
        Die Verwaltungsoberfläche ist das Herzstück der Plattform.
      </p>
      <p class="responsive-lead">
        Zentrale Anlaufstelle für alle Anwendungen und Funktionen für
        Administratoren und Benutzer
      </p>
    </div>
  </div>
</div>

<!-- mail-video -->
<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 class="responsive-display-4">Mail</h1>
    <div class="row">
      <video
        width="auto"
        height="90%"
        controls
        mute
        id="eevemail"
        class="col-md-6 order-md-2"
        (play)="clickedVideo('eevemail')"
      >
        <source
          src="https://czichos.net/assets/images/eeve-videos/eeve-mail.mp4"
          type="video/mp4"
        />
      </video>
      <div class="col-md-6 order-md-1" id="Verwaltungsoberflaeche">
        <p class="responsive-lead">E-Mail mit Webmail und Groupware</p>
        <p class="responsive-lead">
          Mailkonten mit eigener oder eeve.online Domain. Darüber hinaus bietet
          der Webmailer viele Funktionen, welche die Arbeit erleichtern
        </p>
      </div>
    </div>
  </div>
</div>

<!-- voip-video -->
<div class="container container-white">
  <h1 class="responsive-display-4">Telefonie</h1>
  <div class="row">
    <video
      width="auto"
      height="90%"
      controls
      mute
      id="eevevoip"
      class="col-md-6"
      (play)="clickedVideo('eevevoip')"
    >
      <source
        src="https://czichos.net/assets/images/eeve-videos/eeve-voip.mp4"
        type="video/mp4"
      />
    </video>
    <div class="col-md-6" id="Verwaltungsoberflaeche">
      <p class="responsive-lead">
        VoIP Telefonanlage mit der Möglichkeit zum Selbstadministrieren.
      </p>
      <p class="responsive-lead">
        Einfache Bedienung und die kontrolle über alles rund um das Thema
        Telefonie.
      </p>
    </div>
  </div>
</div>

<!-- neugierig -->
<div id="buttonText">
  <div class="jumbotron jumbotron-fluid">
    <div class="container neugierig-container">
      <h1 class="responsive-display-4">Neugierig?</h1>
      <img
        src="https://czichos.net/assets/images/eeve-white-on-red.png"
        id="neugierig"
        class="col-md-6"
      />
      <p class="responsive-lead">
        Wir wollen Sie an dieser Stelle nicht mit Demonstrationen überhäufen,
        weil Sie vermutlich auf der Suche nach Kommunikationsdiensten sowieso
        schon mit Informationen anderer Hersteller überflutet werden.
      </p>
      <p class="responsive-lead">
        Wir hoffen, dass wir Ihr Interesse wecken konnten, und würden uns sehr
        freuen, Ihnen persönlich Ihre Fragen beantworten oder Ihnen noch weitere
        Features vorstellen zu dürfen.
      </p>
      <p class="responsive-lead">
        Gerne richten wir Ihnen auch einen Testzugang kostenlos für einen Monat
        mit vollen Funktionalitäten ein.
      </p>
      <p class="responsive-lead">Wir freuen uns auf Sie!</p>
      <a
        type="button"
        href="mailto:info@czichos.net?subject=Anfrage%20eeve.online"
        class="btn btn-secondary btn-lg btn-block"
      >
        Kontakt
      </a>
    </div>
  </div>
</div>
